<template>
  <ion-page>
    <ion-content :fullscreen="true">

      <Header></Header>

      <div class="container">
        <section id="options" class="list-nav mt-lg mb-md">
            <ion-grid>
              <ion-row>
                <ion-col size="12" size-lg="6">
                  <h4>Editar</h4>
                  <h1>Menú del Día</h1>
                </ion-col>
              </ion-row>
              <ion-row style="margin-top: 1rem">
                <ion-col size="12" size-lg="6">
                  <div class="flex">
                    <ion-button @click="exit" shape="round" style="margin-top: 6px" color="dark" fill="outline">
                        <ion-icon slot="start" :icon="arrowBackOutline"></ion-icon>
                        <ion-label>Volver</ion-label>
                    </ion-button>
                    <ion-button @click="save" :disabled="v$.element.$invalid" color="primary" style="margin-top: 6px; margin-left:0.75rem" shape="round">
                        <ion-icon slot="start" :icon="saveOutline"></ion-icon>
                        <ion-label>Guardar</ion-label>
                    </ion-button>
                  </div>
                </ion-col>
                <ion-col size="12" size-lg="6">
                  <ion-buttons class="options-nav">
                    <ion-button @click="confirmDeleteElement($event)" >
                        <ion-icon color="danger" slot="icon-only" :icon="trashOutline"></ion-icon>
                    </ion-button>
                    <ConfirmPopup></ConfirmPopup>
                  </ion-buttons>
                </ion-col>
              </ion-row>
          </ion-grid>
        </section>

        <section id="content" class="mb-lg">
            <ion-grid>
                <ion-row>
                    <ion-col size="12" size-lg="8">
                            <ion-grid>
                                <ion-row class="mb-md">
                                    <h5 class="edit-title">Detalles</h5>
                                    <ion-col size="12" class="box-input" :class="{ error: v$.element.date.$errors.length }">
                                        <label for="name">Día</label>
                                        <Calendar autocomplete="off" class="input" v-model.lazy="v$.element.date.$model" placeholder="Elegir día" dateFormat="dd/mm/yy" />
                                        <!-- error message -->
                                        <div class="input-errors" v-for="(error, index) of v$.element.date.$errors" :key="index">
                                        <h6 class="error-msg">{{ error.$message }}</h6>
                                        </div>
                                    </ion-col>

                                    <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.element.priceFull.$errors.length }">
                                        <label for="price">Precio Menú Completo (I.V.A. Incl.)</label>
                                        <InputNumber id="price" class="inputNumber secondary-input" v-model.lazy="v$.element.priceFull.$model" :min="0" showButtons mode="currency" currency="EUR" locale="es-ES" />

                                        <!-- error message -->
                                        <div class="input-errors" v-for="(error, index) of v$.element.priceFull.$errors" :key="index">
                                        <h6 class="error-msg">{{ error.$message }}</h6>
                                        </div>
                                    </ion-col>

                                    <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.element.priceHalf.$errors.length }">
                                        <label for="price">Pecio Medio Menú (I.V.A. Incl.)</label>
                                        <InputNumber id="price" class="inputNumber" v-model.lazy="v$.element.priceHalf.$model" :min="0" showButtons mode="currency" currency="EUR" locale="es-ES" />

                                        <!-- error message -->
                                        <div class="input-errors" v-for="(error, index) of v$.element.priceHalf.$errors" :key="index">
                                        <h6 class="error-msg">{{ error.$message }}</h6>
                                        </div>
                                    </ion-col>
                                </ion-row>

                                  <ion-row class="mb-md">
                                    <ion-col size="12">
                                    <h5 class="edit-title">Imagen</h5>
                                        <FileUpload
                                            mode="basic"
                                            name="image"
                                            :multiple="false"
                                            accept="image/*"
                                            :maxFileSize="5000000"
                                            :customUpload="true"
                                            @uploader="handleImageUpload"
                                            :auto="true"
                                            chooseLabel="Subir Imagen"
                                            class="uploader"
                                            ref="uploader"
                                        />
                                            <p style="margin-top: 1rem;" v-if="!images.length">No hay imagen</p>
                                            <draggable class="imagenes">
                                            <div
                                                class="imagen-container" v-for="image in images" :key="image"
                                            >
                                                <Image :src="image.objectURL" alt="image preview" class="imagen" preview />
                                                <ion-buttons class="imagen-remover">
                                                    <ion-button @click="handleImageRemove($event, image.objectURL)">
                                                    <ion-icon :icon="closeCircleOutline" color="danger"></ion-icon>
                                                    </ion-button>
                                                </ion-buttons>
                                            </div>
                                            </draggable>
                                    </ion-col>
                                </ion-row>

                                <ion-row class="mb-md">
                                    <h5 class="edit-title">Menú</h5>
                                    <ion-col size="12">
                                        <ion-item lines="none">
                                            <ion-label><b>Primeros</b></ion-label>
                                            <ion-buttons slot="end">
                                                <ion-button @click="addFirst" >
                                                    <ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
                                                </ion-button>
                                            </ion-buttons>
                                        </ion-item>
                                        <DataTable :value="element.first" @cell-edit-complete="onCellEditFirstComplete" dataKey="id" editMode="cell" class="p-datatable-sm edit-datatable"
                                            @rowReorder="reorderFirst"  responsiveLayout="scroll">
                                            <template #empty>
                                                No hay Primeros
                                            </template>
                                            <Column :rowReorder="true" style="width: 64px; cursor: move;" :reorderableColumn="false"/>
                                            <Column field="name" header="Nombre">
                                                <template #editor="slotProps">
                                                    <InputText class="input" v-model="slotProps.data[slotProps.column.props.field]" />
                                                </template>
                                            </Column>
                                            <Column alignFrozen="right" style="flex-grow:1; flex-basis:50px; min-width: 50px; width: 50px;">
                                                <template #body="slotProps">
                                                    <ion-buttons>
                                                        <ion-button @click="removeFirst($event, slotProps.index)" >
                                                            <ion-icon slot="icon-only" :icon="removeCircleOutline"></ion-icon>
                                                        </ion-button>
                                                    </ion-buttons>
                                                </template>
                                            </Column>
                                        </DataTable>
                                    </ion-col>

                                    <ion-col size="12">
                                        <ion-item lines="none" style="margin-top: 2rem">
                                            <ion-label><b>Segundos</b></ion-label>
                                            <ion-buttons slot="end">
                                                <ion-button @click="addSecond" >
                                                    <ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
                                                </ion-button>
                                            </ion-buttons>
                                        </ion-item>
                                        <DataTable :value="element.second" @cell-edit-complete="onCellEditSecondComplete" dataKey="id" editMode="cell" class="p-datatable-sm edit-datatable"
                                            @rowReorder="reorderSecond"  responsiveLayout="scroll">
                                            <template #empty>
                                                No hay Segundos
                                            </template>
                                            <Column :rowReorder="true" style="width: 64px; cursor: move;" :reorderableColumn="false"/>
                                            <Column field="name" header="Nombre">
                                                <template #editor="slotProps">
                                                    <InputText class="input" v-model="slotProps.data[slotProps.column.props.field]" />
                                                </template>
                                            </Column>
                                            <Column alignFrozen="right" style="flex-grow:1; flex-basis:50px; min-width: 50px; width: 50px;">
                                                <template #body="slotProps">
                                                    <ion-buttons>
                                                        <ion-button @click="removeSecond($event, slotProps.index)" >
                                                            <ion-icon slot="icon-only" :icon="removeCircleOutline"></ion-icon>
                                                        </ion-button>
                                                    </ion-buttons>
                                                </template>
                                            </Column>
                                        </DataTable>
                                    </ion-col>

                                    <ion-col size="12">
                                        <ion-item lines="none" style="margin-top: 2rem">
                                            <ion-label><b>Postres</b></ion-label>
                                            <ion-buttons slot="end">
                                                <ion-button @click="addDesserts" >
                                                    <ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
                                                </ion-button>
                                            </ion-buttons>
                                        </ion-item>
                                        <DataTable :value="element.desserts" @cell-edit-complete="onCellEditDessertsComplete" dataKey="id" editMode="cell" class="p-datatable-sm edit-datatable"
                                            @rowReorder="reorderDesserts"  responsiveLayout="scroll">
                                            <template #empty>
                                                No hay Primeros
                                            </template>
                                            <Column :rowReorder="true" style="width: 64px; cursor: move;" :reorderableColumn="false"/>
                                            <Column field="name" header="Nombre">
                                                <template #editor="slotProps">
                                                    <InputText class="input" v-model="slotProps.data[slotProps.column.props.field]" />
                                                </template>
                                            </Column>
                                            <Column alignFrozen="right" style="flex-grow:1; flex-basis:50px; min-width: 50px; width: 50px;">
                                                <template #body="slotProps">
                                                    <ion-buttons>
                                                        <ion-button @click="removeDesserts($event, slotProps.index)" >
                                                            <ion-icon slot="icon-only" :icon="removeCircleOutline"></ion-icon>
                                                        </ion-button>
                                                    </ion-buttons>
                                                </template>
                                            </Column>
                                        </DataTable>
                                    </ion-col>

                                    <ion-col size="12">
                                        <ion-item lines="none" style="margin-top: 2rem">
                                            <ion-label><b>Bebidas</b></ion-label>
                                            <ion-buttons slot="end">
                                                <ion-button @click="addDrinks" >
                                                    <ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
                                                </ion-button>
                                            </ion-buttons>
                                        </ion-item>
                                        <DataTable :value="element.drinks" @cell-edit-complete="onCellEditDrinksComplete" dataKey="id" editMode="cell" class="p-datatable-sm edit-datatable"
                                            @rowReorder="reorderDrinks"  responsiveLayout="scroll">
                                            <template #empty>
                                                No hay Primeros
                                            </template>
                                            <Column :rowReorder="true" style="width: 64px; cursor: move;" :reorderableColumn="false"/>
                                            <Column field="name" header="Nombre">
                                                <template #editor="slotProps">
                                                    <InputText class="input" v-model="slotProps.data[slotProps.column.props.field]" />
                                                </template>
                                            </Column>
                                            <Column alignFrozen="right" style="flex-grow:1; flex-basis:50px; min-width: 50px; width: 50px;">
                                                <template #body="slotProps">
                                                    <ion-buttons>
                                                        <ion-button @click="removeDrinks($event, slotProps.index)" >
                                                            <ion-icon slot="icon-only" :icon="removeCircleOutline"></ion-icon>
                                                        </ion-button>
                                                    </ion-buttons>
                                                </template>
                                            </Column>
                                        </DataTable>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>

                            <ion-button @click="showPreview" color="dark" fill="outline" expand="block"  class="hide-xl hide-lg" style="margin-top:0.5rem" shape="round">
                                <ion-label>Previsualizar</ion-label>
                            </ion-button>
                    </ion-col>

                    <ion-col size="12" size-lg="4" class="hide-xs hide-sm hide-md">
                        <DishPreview :data="element"></DishPreview>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </section>
      </div>

      <Footer></Footer>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonButtons, IonButton, IonLabel, IonIcon, IonItem, IonList, modalController} from '@ionic/vue';
import { saveOutline, trashOutline, moveOutline, addCircleOutline, removeCircleOutline, ellipsisVerticalOutline, languageOutline, checkmarkCircleOutline, closeCircleOutline, arrowBackOutline } from 'ionicons/icons';
import ConfirmPopup from 'primevue/confirmpopup';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Calendar from 'primevue/calendar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import FileUpload from 'primevue/fileupload';
import Image from 'primevue/image';

import { defineComponent } from 'vue';
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import DishPreview from './components/DishPreview.vue';

import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@/utils/i18n-validators'

import { mapActions, mapState } from 'vuex';
import moment from 'moment';

export default defineComponent({
  name: 'Editelement',
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    Footer,
    ConfirmPopup,
    Header,
    IonButtons,
    IonButton,
    IonLabel,
    IonIcon,
    InputNumber,
    Calendar,
    DataTable,
    InputText,
    Column,
    IonItem,
    FileUpload,
    Image,
    DishPreview
  },
  data() {
      return {
        element: { 
            first: [],
            second: [],
            desserts: [],
            drinks: [],
            priceFull: 0,
            priceHalf: null,
            date: new Date()
        },
        language: 'es',
        images: [],
      };
    },
    validations() {
        return {
            element: {
                date: {required},
                priceFull: {required},
                priceHalf: {}
            },
        }
    },
    computed: {
        ...mapState('restaurant', {
            todaysMenu: state => state.todaysMenu,
        }),

        ...mapState(['evolbeUser'])
    },
    methods: {
      ...mapActions('restaurant',['getTodaysMenu', 'deleteTodaysMenu', 'updateTodaysMenu', 'addTodaysMenu']),
      ...mapActions(['uploadImage', 'uploadImageMenu']),


    // Images
      handleImageUpload(event){
        event.files.forEach(file => {
          const epoch = new Date().getTime();
          file.id = epoch + '-' + Math.floor(Math.random() * 1000000);
          
          this.images.push(file);
          this.uploadImage({ruta: '/evolbe-modules/restaurant/todays-menu', img: file});
          this.element.images.push({
            '256': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Frestaurant%2Ftodays-menu%2Fthumbnails%2F' + file.id + '_256x256.webp?alt=media',
            '512': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Frestaurant%2Ftodays-menu%2Fthumbnails%2F' + file.id + '_512x512.webp?alt=media',
            '1024': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Frestaurant%2Ftodays-menu%2Fthumbnails%2F' + file.id + '_1024x1024.webp?alt=media',
            '2048': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Frestaurant%2Ftodays-menu%2Fthumbnails%2F' + file.id + '_2048x2048.webp?alt=media',
          });

          this.uploadImageMenu({ruta: '/evolbe-modules/restaurant/todays-menu', img: file});
        });

        this.$refs.uploader.clear();
      },
      handleImageRemove(event, url) {
        const pos0 = this.images.findIndex(x => x.objectURL === url);
        this.images.splice(pos0, 1);

        const pos = this.element.images.findIndex(x => x[512] === url);
        this.element.images.splice(pos, 1);
      },
      handleImagePreview(event, url) {
        this.dialogImageUrl = url;
        this.dialogVisible = true;
      },

      // Fisrt
      addFirst(){
        if(!this.element.first){
          this.element.first = [];
        }

        this.element.first.push({
          name: 'Nuevo Primero',
        })
      },
      removeFirst(event, pos){
        this.element.first.splice(pos, 1);
      },
      reorderFirst(event){
        this.element.first = event.value;
      },

      onCellEditFirstComplete(event){
        this.element.first[event.index][event.field] = event.newValue;
      },

      // Second
      addSecond(){
        if(!this.element.second){
          this.element.second = [];
        }

        this.element.second.push({
          name: 'Nuevo Segundo',
        })
      },
      removeSecond(event, pos){
        this.element.second.splice(pos, 1);
      },
      reorderSecond(event){
        this.element.second = event.value;
      },

      onCellEditSecondComplete(event){
        this.element.second[event.index][event.field] = event.newValue;
      },

      // Desserts
      addDesserts(){
        if(!this.element.desserts){
          this.element.desserts = [];
        }

        this.element.desserts.push({
          name: 'Nuevo Postre',
        })
      },
      removeDesserts(event, pos){
        this.element.desserts.splice(pos, 1);
      },
      reorderDesserts(event){
        this.element.desserts = event.value;
      },

      onCellEditDessertsComplete(event){
        this.element.desserts[event.index][event.field] = event.newValue;
      },

      // Drinks
      addDrinks(){
        if(!this.element.drinks){
          this.element.drinks = [];
        }

        this.element.drinks.push({
          name: 'Nueva Bebida',
        })
      },
      removeDrinks(event, pos){
        this.element.drinks.splice(pos, 1);
      },
      reorderDrinks(event){
        this.element.drinks = event.value;
      },

      onCellEditDrinksComplete(event){
        this.element.drinks[event.index][event.field] = event.newValue;
      },



      // Actions
      save(){
        this.element.name = moment(this.element.date).format('dddd, DD MMMM YYYY');
        this.element.date = moment(this.element.date).format('MM/DD/YYYY');

        if(this.$route.params.id === 'new'){
          this.element.dateCreated = new Date().getTime();
          this.element.dateUpdated = new Date().getTime();

          this.addTodaysMenu(this.element);
          this.exit();
        } else {
          this.element.dateUpdated = new Date().getTime();
          this.updateTodaysMenu({data: this.element, id: this.$route.params.id});
          this.exit();
        }
      },
      confirmDeleteElement(event){
        this.$confirm.require({
                target: event.currentTarget,
                message: '¿Seguro de que quieres eliminar ese elemento?',
                acceptLabel: 'Si',
                rejectLabel: 'No',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    //callback to execute when evolbeUser confirms the action
                    this.deleteTodaysMenu(this.$route.params.id);
                    this.exit();
                },
                reject: () => {
                    //callback to execute when evolbeUser rejects the action
                }
            });
         
      },
      exit(){
          this.images = [];
          this.$router.go(-1);
      },

      async showPreview(){
        const modal = await modalController
            .create({
                component: DishPreview,
                cssClass: 'auto-height',
                componentProps: {
                    element: this.element
                },
            })
        return modal.present();
      }
    },
    
    beforeMount() {
        this.getTodaysMenu(this.$route.params.id).then(() => {
            if(this.todaysMenu){
                this.element = this.todaysMenu;

                this.element.date = new Date(this.todaysMenu.date);

                // Load Images
                if(this.element && !this.images.length){
                  this.element.images.forEach(img => {
                    this.images.push({objectURL: img['512'], status: 'upload'});
                  });
                }
            }
        })
    },
  setup() {
    return { v$: useVuelidate(), saveOutline, trashOutline, moveOutline, ellipsisVerticalOutline, addCircleOutline, removeCircleOutline, languageOutline, checkmarkCircleOutline, arrowBackOutline, closeCircleOutline};
  }
});
</script>

<style scoped>
  .edit-title{
    padding-bottom: 1rem;
  }

  .imagenes{
    margin-top: 1rem;
    display: flex;
    overflow: hidden;
    display: grid;
  }

  .imagenes .imagen-container{
    position: relative;
    margin: 0.5rem
  }

  .imagenes .imagen-container .imagen{
    width: 100%;
    height: 212px;
    border-radius: 1rem;
    overflow: hidden;
  }

  .imagenes .imagen-container .imagen-remover{
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .allergen-item img{
    width: 48px;
    height: auto;
  }
</style>